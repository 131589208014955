import React, { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CLabel, CSelect, CFormGroup, CProgress, CProgressBar, CModalHeader, CModal, CModalFooter, CModalBody, CModalTitle, CSwitch, CInput, CBadge, CTooltip } from '@coreui/react';

import Project from '../../assets/img/project.png';

import 'react-circular-progressbar/dist/styles.css';
import './Projects.css';

import { useHistory, useLocation } from 'react-router-dom';
import $axios from 'src/utils/axios';
import Loading from '../aimhi-widgets/Loading';

import EmptyDashboard from '../../assets/img/dashboard_empty.png';

import { FaArchive, FaUndoAlt, FaUserLock, FaTimes, FaSortUp, FaSortDown, FaCopy, FaTrash } from 'react-icons/fa';
import LoadingLottie from '../../json/data_available';
import Lottie from 'react-lottie';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { requestMaterialNotifications, requestSowNotifications } from 'src/redux/actions/notifications';
import { ArchivesRestore, GetllMembersProject, ProjectList, SaveProjectAccess, DuplicateProject, DeleteProject, ArchiveSelected, RestoreSelected } from 'src/request/Projects';
import Select from 'react-select';
import queryString from 'query-string';

const Projects = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const history = useHistory();
  const [projectData, setprojectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectsFilter, setProjectsFilter] = useState({});
  const [filters, setfilters] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [postperPage, setpostperPage] = useState(12);
  const [arhiveButton, setArchiveButton] = useState(0);
  const [projectProgress, setProjectProgress] = useState(0);
  const [filterStatus, setFilterStatus] = useState('');
  const [sort, setSort] = useState('');
  const [modal, setModal] = useState(false);
  const [members, setMember] = useState([]);
  const [remove, setRemove] = useState([]);
  const [all, setAll] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [userList, setUsersList] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [order, setOrder] = useState('asc');
  const [selectedProject, setSelectedProject] = useState([]);
  const Swal = require('sweetalert2');
  let permission = useSelector((state) => state.permission);

  const { search } = useLocation();
  const values = queryString.parse(search);
  useEffect(() => {
    setLoading(true);
    setProjectsFilter({}, '');
    getAllProject({});
    listofusers({ role: null, status: 1 });
  }, []);

  const Copy = (project_name, project_id) => {
    Swal.fire({
      title: project_name,
      text: 'Are you sure you want to duplicate this project?',
      icon: 'question',
      confirmButtonColor: '#23303d',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Duplicate',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        DuplicateProject(project_id)
          .then((response) => {
            console.log(response, 'QWEQWE');

            if (response.status == 200) {
              Swal.fire(`${project_name} copied successfully`, '', 'success');
              getAllProject({
                filter: { status: filterStatus },
                sort: { by: sort, order: order },
                val: 1,
              });
            } else {
              Swal.fire(`Something went wrong. Project not copied`, '', 'error');
            }
          })
          .catch(() => {
            Swal.fire(`Something went wrong. Project not copied`, '', 'error');
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const listofusers = (filter = {}) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .get(`account/list/${company_id}?status=${filter.status}&role=${filter.role}`)
      .then(function (response) {
        if (response.data.status == 1) {
          let users = response.data.response.data;
          let arr = [...userList];
          users.forEach((i) => {
            arr.push({
              label: i['full_name'],
              value: i['id'],
            });
          });

          console.log(arr);

          setUsersList(arr);
          console.log(response, 'list user');
          // setUsersList(response.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.response,
            text: response.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      })
      .catch((error) => {
        console.log('roles/Roles.js', ' ', 'line 117', error);
      });
  };

  const AddMember = (val) => {
    let arr = [...members];

    let check = arr.filter((a) => a.value == val.value);
    console.log(check, 'check');
    if (check.length == 0) {
      arr.push({
        name: val.label,
        id: val.value,
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: val.label,
        text: 'Already in the list',
        confirmButtonColor: '#23303d',
      });
    }

    setMember(arr);
  };

  const removeMember = (Index, Id) => {
    let arr = [...members];
    let removearr = [...remove];
    removearr.push(Id);

    arr.splice(Index, 1);
    setRemove(removearr);
    setMember(arr);
  };

  const AddProject = (event) => {
    props.ParentCallback(event);
  };

  const viewproject = (id, type, name, start, end, estimate_status, identifier) => {
    let data = {
      id,
      type,
      name,
      start,
      end,
      estimate_status,
      identifier,
    };

    const company_id = parseInt(localStorage.getItem('company_id'));

    props.clickOverview(data, 'Overview');
    requestMaterialNotifications(company_id, id);
    requestSowNotifications(company_id, id);
  };

  const indexOfLastPost = currentPage * postperPage;
  const indexOfFirstPost = indexOfLastPost - postperPage;

  const searchProjects = (e) => {
    setSearchValue(e.target.value);
  };
  const currentPost = projectData.filter((x) => x.ProjectName.toLowerCase().includes(searchValue.toLowerCase())).slice(indexOfFirstPost, indexOfLastPost);
  const goConnect = () => {
    props.ParentCallback('AddProject');
  };

  const getAllProject = ({ filter = {}, val = null, archive = null, sort = {} }) => {
    console.log(filter, val, 'test filter');
    console.log(archive);
    console.log(val);
    setArchiveButton(archive);
    let filterIsEmpty = filter.status == '' ? {} : filter;

    let projectFilter = {};

    setfilters(val);

    // if there are filters, append to ProjectsFilter state
    if (Object.keys(filterIsEmpty).length > 0) {
      projectFilter = { ...projectsFilter };
      for (const [key, value] of Object.entries(filter)) {
        projectFilter[key] = value;
      }
      setProjectsFilter(projectFilter);
    }
    // reset ProjectsFilter state if there are no filters
    else {
      setProjectsFilter({});
    }

    ProjectList(projectFilter, '', sort)
      .then(function (res) {
        if (res.data.status == 1) {
          console.log(res.data.response);
          setprojectData(res.data.response);
          // setTotalItems(res.data.response.total_items);

          console.log(projectData, 'JANREY');
          setLoading(false);
          if (values?.id) {
            const id = !isNaN(values?.id) ? parseInt(values?.id) : null;
            const data = res.data.response.find((e) => e.ProjectId === id);
            if (data) {
              viewproject(data.ProjectId, data.ProjectType, data.ProjectName, data.Start, data.End, data.estimate_status, data.identifier);
            }
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      })
      .catch((error) => {
        console.log('projects/Projects.js', ' ', 'line 145', error);
      });
  };

  const arhiveproject = (id, projectname) => {
    Swal.fire({
      icon: 'question',
      title: "<span style='color:#B42A19;'>Are you sure</span>",
      html: "<p style='font-weight:bold'>If you proceed, equipment and manpower will<br>be unassigned. Are you sure you want to <br> continue?</p>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#39f',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        ArchivesRestore(id, 'delete')
          .then(function (response) {
            if (response.data.status == 1) {
              // getAllProject();
              getAllProject({});
              Swal.fire('Success', 'succesfully archive', 'success');
            } else {
            }
          })
          .catch((error) => {
            console.log('projects/Projects.js', ' ', 'line 181', error);
          });
      }
    });
  };

  const restoreProject = (id, projectname) => {
    Swal.fire({
      icon: 'question',
      title: "<span style='color:#B42A19;'>Are you sure</span>",
      html: "<p style='font-weight:bold'>If you proceed,you will restore all your <br> data.Are you sure you want to <br> retore this projects?</p>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#39f',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        ArchivesRestore(id, 'put')
          .then(function (response) {
            if (response.data.status == 1) {
              // getAllProject();
              getAllProject({});
              Swal.fire('Success', 'succesfully restore', 'success');
            } else {
            }
          })
          .catch((error) => {
            console.log('projects/Projects.js', ' ', 'line 181', error);
          });
      }
    });
  };

  const memberAccess = (project_id) => {
    GetllMembersProject(project_id).then((res) => {
      if (res.data.status == 1) {
        setAll(res.data.response.all_access == 1);
        setMember(res.data.response.members);
      }
    });
  };

  const SaveAccess = () => {
    let data = [];

    if (all) {
      data = null;
    } else {
      members.forEach((member) => {
        data.push(member.id);
      });
    }

    SaveProjectAccess(data, projectId).then((res) => {
      if (res.data.status == 1) {
        setModal(!modal);
        setMember([]);
      }
    });
  };

  const UserMember = members.map((data, Index) => {
    return (
      <CCol md="4" key={Index} style={{ padding: '0px', marginTop: '5px' }}>
        <div className="members">
          <span>
            <span className="font-responsive">{data.name} </span>

            <FaTimes className="float-right" onClick={() => removeMember(Index, data.id)} />
          </span>
        </div>
      </CCol>
    );
  });

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDeleteProject = (projectName, projectId) => {
    Swal.fire({
      title: projectName,
      text: 'Are you sure you want to delete this project?',
      icon: 'question',
      confirmButtonColor: '#23303d',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((res) => {
      if (res.isConfirmed) {
        DeleteProject(projectId).then((res) => {
          if (res.data.status === 200) {
            getAllProject({});
            Swal.fire({
              title: 'Success',
              text: 'Project deleted successfully',
              icon: 'success',
            });
          }
        });
      }
    });
  };

  const handleSelectedProject = (e, projectID) => {
    e.stopPropagation();
    const exist = selectedProject.includes(projectID);
    if (!exist) {
      setSelectedProject((prevData) => [...prevData, projectID]);
    } else {
      setSelectedProject((prevData) => prevData.filter((item) => item !== projectID));
    }
  };

  const handleArchiveSelected = () => {
    if (selectedProject.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'No Items Selected',
        text: 'Please select at least one project to proceed.',
      });
    } else {
      const text = arhiveButton === 1 ? 'If you proceed, the project will be restored. <br>Are you sure you want to continue?' : 'If you proceed, equipment and manpower will<br>be unassigned. Are you sure you want to <br> continue?';
      Swal.fire({
        icon: 'question',
        title: "<span style='color:#B42A19;'>Are you sure</span>",
        html: `<p style='font-weight:bold'>${text}</p>`,
        showCancelButton: true,
        confirmButtonColor: '#39f',
        cancelButtonColor: '#B42A19',
        confirmButtonText: 'Proceed',
      }).then((result) => {
        if (result.isConfirmed) {
          const endpoints = arhiveButton === 1 ? RestoreSelected : ArchiveSelected;
          endpoints(selectedProject).then((res) => {
            if (res.data.status === 200) {
              Swal.fire({
                title: 'Success',
                icon: 'success',
                text: res?.data?.response?.message,
              });
              getAllProject({});
              setFilterStatus('');
              setSort('');
              setSearchValue('');
              setSelectedProject([]);
            }
          });
        }
      });
    }
  };
  const project = currentPost.map((item, Index) => {
    return (
      <CCol xs="12" sm="12" md="6" lg="6" xl="4" key={Index}>
        <div onClick={() => viewproject(item.ProjectId, item.ProjectType, item.ProjectName, item.Start, item.End, item.estimate_status, item.identifier)}>
          <CCard className="box-shadow">
            <CCardHeader className="card-header-project" style={{ padding: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <CInput type="checkbox" name="smallCheckbox" onClick={(e) => handleSelectedProject(e, item.ProjectId)} checked={selectedProject.includes(item.ProjectId)} style={{ maxWidth: '16px', maxHeight: '16px', marginRight: '8px' }} role="button" />
                <span className="font-with-responsive" title={item.ProjectName}>
                  {item.ProjectName}
                </span>
                {!item?.has_historical_data ? (
                  <CTooltip content="This project lacks historical data, which may affect the accuracy of the recommendations, forecasts, and risk analysis." style={{ padding: '0' }}>
                    <CBadge color="aimhi-danger" shape="pill" style={{ padding: '4px 8px' }}>
                      No Historical Data
                    </CBadge>
                  </CTooltip>
                ) : null}
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {permission['delete'][0] == 1 && arhiveButton == 1 && (
                  <FaUndoAlt
                    style={{ width: '25px' }}
                    role="button"
                    onClick={(e) => {
                      stopPropagation(e);
                      restoreProject(item.ProjectId, item.ProjectName);
                    }}
                  />
                )}
                {arhiveButton != 1 && (
                  <span>
                    {permission['role_id'] == 1 && (
                      <FaUserLock
                        style={{ width: '25px' }}
                        role="button"
                        onClick={(e) => {
                          stopPropagation(e);
                          memberAccess(item.ProjectId, setModal(!modal), setProjectId(item.ProjectId));
                        }}
                      />
                    )}
                    {permission['view'][0] == 1 && permission['create'][0] == 1 && permission['update'][0] == 1 && (
                      <>
                        <FaArchive
                          style={{ width: '25px' }}
                          role="button"
                          onClick={(e) => {
                            stopPropagation(e);
                            arhiveproject(item.ProjectId, item.ProjectName);
                          }}
                        />
                        <FaCopy
                          style={{ width: '25px' }}
                          role="button"
                          onClick={(e) => {
                            stopPropagation(e);
                            Copy(item.ProjectName, item.ProjectId);
                          }}
                        />
                      </>
                    )}
                    {permission['delete'][0] == 1 && (
                      <>
                        <FaTrash
                          style={{ width: '25px' }}
                          role="button"
                          onClick={(e) => {
                            stopPropagation(e);
                            handleDeleteProject(item.ProjectName, item.ProjectId);
                          }}
                        />
                      </>
                    )}
                  </span>
                )}
              </div>
              <CCol xl="12" style={{ padding: '0px' }}>
                <span className="font-with-responsive">Project ID : {item.identifier}</span>
              </CCol>
            </CCardHeader>
            <CCardBody style={{ padding: '10px' }}>
              <CRow>
                <CCol xs="12" sm="12" md="4" lg="4" xl="4">
                  {item.Image == '' ? <img src={Project} alt="Avatar" className="project-img-size-project" /> : <img src={item.Image} alt="Avatar" className="project-img-size-project" />}
                </CCol>
                <CCol xs="12" sm="12" md="8" lg="8">
                  <CRow>
                    <CCol xs="12" sm="12" md="6" lg="6">
                      <span className="card-label">Total Project Cost</span>
                    </CCol>
                    <CCol xs="12" sm="12" md="6" lg="6" style={{ textAlign: 'right' }}>
                      <span className="card-value-project">
                        P
                        <NumberFormat value={item.TotalCost} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </span>
                    </CCol>
                    <CCol xs="12" sm="12" md="6" lg="6">
                      <span className="card-label">Total Spent</span>
                    </CCol>
                    <CCol xs="12" sm="12" md="6" lg="6" style={{ textAlign: 'right' }}>
                      <span className="card-value-project">
                        P
                        <NumberFormat value={item.TotalSpent} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} style={{ marginLeft: '5px' }} />
                      </span>
                    </CCol>
                    <CCol xs="2" sm="2" md="2" lg="2">
                      <span className="card-label">{item.Progress}%</span>
                    </CCol>
                    <CCol xs="10" sm="10" md="10" lg="10">
                      <CProgress size="md">
                        <CProgressBar color="aimhi-primary" value={item.Progress} />
                      </CProgress>
                      <CRow>
                        <CCol xs="6" sm="6" md="6" lg="6">
                          <span className="start" style={{ fontWeight: 'bold' }}>
                            START: {item.Start}
                          </span>
                        </CCol>
                        <CCol xs="6" sm="6" md="6" lg="6">
                          <span className="end" style={{ fontWeight: 'bold' }}>
                            END: {item.End}
                          </span>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
    );
  });

  return (
    <>
      {loading ? <Loading /> : ''}
      {projectData.length == 0 && filters == 0 ? (
        <CRow>
          <CCol sm="12" md="12" lg="12" xl="12" style={{ textAlign: 'center' }}>
            <img src={EmptyDashboard} alt="Avatar" style={{ width: '30%' }} />
          </CCol>
          <CCol sm="12" md="12" lg="12" xl="12" style={{ textAlign: 'center' }}>
            <span>Start building your data by adding your projects.</span>
            <CCol sm="12" md="12" lg="12" xl="12" style={{ textAlign: 'center', marginTop: '10px' }}>
              <CButton color="aimhi-info" onClick={goConnect}>
                Go to CONNECT
              </CButton>
            </CCol>
          </CCol>
        </CRow>
      ) : (
        <div>
          <CRow className="font">
            <CCol xs="12" sm="12" md="8" lg="8">
              <span className="Title">Projects</span>
              <div></div>
            </CCol>
            <CCol xs="12" sm="12" md="12" lg="9" xl="9" style={{ paddingTop: '20px' }}>
              <CFormGroup row>
                <CCol xs="12" sm="12" md="2" lg="2" className="margin-mobile float-left">
                  <CSelect
                    name="select"
                    id="select"
                    size="sm"
                    onChange={(e) => {
                      getAllProject({
                        filter: { status: e.target.value },
                        sort: { by: sort, order: order },
                        val: 1,
                      });
                      setFilterStatus(e.target.value);
                      setProjectProgress(e.target.value);
                    }}
                    value={filterStatus}
                  >
                    <option disabled value="">
                      Filter by status
                    </option>
                    <option value={0}>Not Started</option>
                    <option value={10}>10%</option>
                    <option value={20}>20%</option>
                    <option value={30}>30%</option>
                    <option value={40}>40%</option>
                    <option value={50}>50%</option>
                    <option value={60}>60%</option>
                    <option value={70}>70%</option>
                    <option value={80}>80%</option>
                    <option value={90}>90%</option>

                    <option value={100}>Completed</option>
                  </CSelect>
                </CCol>
                <CCol xs="12" sm="12" md="2" lg="2" className="margin-mobile float-left">
                  <CInput id="name" size="sm" onChange={searchProjects} placeholder="Enter project name" value={searchValue} required />
                </CCol>
                <CCol xs="12" sm="12" md="3" lg="2" className="margin-mobile">
                  <CRow>
                    <CCol xs="11" sm="11" md="11" lg="10">
                      <CSelect
                        name="select"
                        id="select"
                        size="sm"
                        onChange={(e) => {
                          getAllProject({
                            filter: { status: filterStatus },
                            sort: { by: e.target.value, order: order },
                            val: 1,
                          });
                          setSort(e.target.value);
                        }}
                        value={sort}
                      >
                        <option disabled value="">
                          Sort By
                        </option>
                        <option value="project_name">Project Name</option>
                        <option value="start_date">Start Date</option>
                        <option value="end_date">End Date</option>
                        <option value="cost">Cost</option>
                      </CSelect>
                    </CCol>
                    <CCol xs="1" sm="1" md="1" lg="2">
                      {order == 'asc' ? (
                        <FaSortUp
                          hidden={!sort} // hide if sort is empty
                          style={{ height: '30px', width: '30px' }}
                          onClick={(e) => {
                            getAllProject({
                              sort: { by: sort, order: 'desc' },
                              val: 1,
                            });
                            setOrder(order == 'asc' ? 'desc' : 'asc');
                          }}
                        />
                      ) : (
                        <FaSortDown
                          hidden={!sort} // hide if sort is empty
                          style={{ height: '30px', width: '30px' }}
                          onClick={(e) => {
                            getAllProject({
                              sort: { by: sort, order: 'asc' },
                              val: 1,
                            });
                            setOrder(order == 'asc' ? 'desc' : 'asc');
                          }}
                        />
                      )}
                    </CCol>
                  </CRow>
                </CCol>

                <CCol xs="12" sm="12" md="2" lg="2" className="margin-mobile">
                  <CButton
                    color="aimhi-textlight-neutral"
                    variant="outline"
                    size="sm"
                    block
                    onClick={(e) => {
                      getAllProject({});
                      setFilterStatus('');
                      setSort('');
                      setSearchValue('');
                      setSelectedProject([]);
                    }}
                  >
                    Reset
                  </CButton>
                </CCol>
                {selectedProject?.length > 0 && (
                  <CCol xs="12" sm="12" md="2" lg="2" className="margin-mobile">
                    <CButton color="aimhi-textlight-neutral" variant="outline" size="sm" block onClick={handleArchiveSelected}>
                      {arhiveButton === 1 ? 'Restore' : 'Archive'} ({selectedProject.length}) Items
                    </CButton>
                  </CCol>
                )}
                <CCol xs="12" sm="12" md="2" lg="2" className="margin-mobile">
                  <CButton
                    color="aimhi-textlight-neutral"
                    size="sm"
                    block
                    onClick={(e) => {
                      getAllProject({
                        filter: { archive: 1 },
                        val: 1,
                        archive: 1,
                      });
                      setSelectedProject([]);
                    }}
                  >
                    Archives
                  </CButton>
                </CCol>
              </CFormGroup>
            </CCol>
            <CCol xs="3" sm="3" md="3" lg="3" xl="3" style={{ paddingTop: '20px' }}>
              <CCol xs="6" sm="6" md="6" lg="6" className="float-right">
                {permission['create'][0] == 1 && (
                  <CButton color="aimhi-textlight-neutral" size="sm" block onClick={() => AddProject('AddProject')}>
                    Add Project
                  </CButton>
                )}
              </CCol>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '30px' }}>
              <CRow>{project}</CRow>
              <CCol md="12" className="justify-content-center align-items-center" style={{ marginBottom: '10px' }}>
                <CRow>
                  <CCol md="5"></CCol>
                  <CCol md="2" style={{ textAlign: 'center' }}>
                    <span style={{ color: '#757070' }}>
                      Viewing {postperPage >= projectData.filter((x) => x.ProjectName.toLowerCase().includes(searchValue.toLowerCase())).length ? projectData.filter((x) => x.ProjectName.toLowerCase().includes(searchValue.toLowerCase())).length : postperPage} of{' '}
                      {projectData.filter((x) => x.ProjectName.toLowerCase().includes(searchValue.toLowerCase())).length} project(s)
                    </span>
                    {postperPage >= projectData.length ? (
                      ''
                    ) : (
                      <CButton color="aimhi-textlight-neutral" size="sm" variant="outline" block onClick={() => setpostperPage(postperPage + 12)}>
                        Load More
                      </CButton>
                    )}
                  </CCol>
                  <CCol md="5"></CCol>
                </CRow>
              </CCol>
              {projectData.length == 0 && filters != 0 && (
                <CCol
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}
                >
                  <Lottie options={defaultOptions} height={200} width={200} className="loading-lottie" style={{ marginTop: '10px' }} />
                  <span>No data result!</span>
                </CCol>
              )}
            </CCol>
          </CRow>
        </div>
      )}
      <CModal show={modal} onClose={() => setModal(!modal)} style={{ borderRadius: '5px' }}>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle> Project Member Access</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol md="12" sm="12" lg="12" xl="12" style={{ padding: '0px' }}>
            <CRow>
              <CCol xl="3">
                <CLabel>Access All</CLabel>
              </CCol>
              <CCol xl="9">
                <CSwitch size="sm" className={'mx-1'} shape={'pill'} color={'aimhi-textlight-neutral'} onChange={() => setAll(!all)} checked={all} />
              </CCol>
            </CRow>

            <Select defaultValue={{ label: '', value: '' }} options={userList} onChange={(e) => AddMember(e)} isDisabled={all} />
          </CCol>
          <CCol md="12" sm="12" lg="12" xl="12">
            <CRow>{UserMember}</CRow>
          </CCol>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton block color="aimhi-primary" size="sm" onClick={SaveAccess}>
              Save
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Projects;
