import React, { useState, useEffect, useRef } from 'react';
import { CButton, CCol, CRow, CInput, CTooltip, CSelect } from '@coreui/react';
import LoadingLottie from '../../json/data_available';
import Loading from '../aimhi-widgets/Loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GetProjectCategory, GetProjectType, SaveProject } from 'src/request/Projects';
import axios from 'axios';

import { FaRegQuestionCircle } from 'react-icons/fa';
const AddProject = (props) => {
  const Swal = require('sweetalert2');
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [projectname, setProjectName] = useState('');
  const [projectlocation, setProjectLocation] = useState('');
  const [projectcategory, setProjectCategory] = useState('');
  const [projectid, setProjectId] = useState('');
  const [projectcost, setProjectCost] = useState('');
  const [contractAmount, setContractAmount] = useState('');
  const [sourcefund, setSourceFund] = useState('');
  const [projectstart, setProjectStart] = useState('');
  const [nodays, setNoDays] = useState('');
  const [projectTypeData, setprojectTypeData] = useState([]);
  const [projectType, setProjectType] = useState('');
  const [projectCategoryData, setProjectCategoryData] = useState([]);
  const [typedate, settypedate] = useState('text');
  const [loading, setLoading] = useState(false);
  const [regionData, setRegion] = useState([]);
  const [provinceData, setProvince] = useState([]);
  const [cityData, setCity] = useState([]);
  const [barangayData, setBarangay] = useState([]);
  const [regionAddr, setRegionAddr] = useState('');
  const [provinceAddr, setProvinceAddr] = useState('');
  const [cityAddr, setCityAddr] = useState('');
  const [barangayAddr, setBarangayAddr] = useState('');
  const [streetAddr, setStreetAddr] = useState('');
  const [ocm, setOcm] = useState('');
  const [cp, setCp] = useState('');
  const [hasVat, setHasVat] = useState('');
  const [REGIONS, setRegionGlobal] = useState([]);
  const [PROVINCES, setProvinceGlobal] = useState([]);
  const [CITIES, setCityGlobal] = useState([]);
  const [BARANGAYS, setBarangayGlobal] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    if (isDataLoaded) {
    ProjectType();
    region();
    ProjectCategory();
    }
  }, [isDataLoaded]);

  // const AddProject = (event) => {
  //   console.log(event)
  //   props.ParentCallback(event)
  // }
  const jsonFiles = ['/static/constants/regions.json', '/static/constants/barangays.json', '/static/constants/provinces.json', '/static/constants/cities.json'];
  const isInit = useRef(true);
  if(isInit.current){
    isInit.current = false;
    Promise.all(jsonFiles.map((file) => axios.get(file)))
    .then((responses) => {
      const [regions, barangays, provinces, cities] = responses.map((res) => res.data);
      setRegionGlobal(regions);
      setProvinceGlobal(provinces);
      setCityGlobal(cities);
      setBarangayGlobal(barangays);
      setIsDataLoaded(true);
    })
    .catch((error) => {
      console.error('Error fetching JSON files:', error);
    });
  }

  const region = () => {
    setRegion(REGIONS);
  };

  const province = (e) => {
    setRegionAddr(e.target.selectedOptions[0].text);
    setProvince(PROVINCES.filter((x) => x.region_code == e.target.value))
    setCity([]);
    setBarangay([]);
  };

  const city = (e) => {
    setProvinceAddr(e.target.selectedOptions[0].text);
    setCity(CITIES.filter((x) => x.province_code == e.target.value))
  };

  const barangay = (e) => {
    setCityAddr(e.target.selectedOptions[0].text);
    setBarangay(BARANGAYS.filter((x) => x.city_code == e.target.value));
  };

  const brgy = (e) => {
    setBarangayAddr(e.target.selectedOptions[0].text);
  };

  const ProjectType = () => {
    GetProjectType()
      .then((res) => {
        if (res.data.status == 1) {
          setprojectTypeData(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
        }

        // setprojectData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log('projects/AddProject.js', ' ', 'line 147', error);
      });
  };

  const ProjectCategory = () => {
    GetProjectCategory()
      .then((res) => {
        if (res.data.status == 1) {
          setProjectCategoryData(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
        }

        // setprojectData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log('projects/AddProject.js', ' ', 'line 147', error);
      });
  };

  const option = projectTypeData.map((item, Index) => {
    return (
      <option value={item.value} key={Index}>
        {item.label}
      </option>
    );
  });

  const categoryOptiom = projectCategoryData.map((item, Index) => {
    return (
      <option value={item.value} key={Index}>
        {item.label}
      </option>
    );
  });

  const Save = () => {
    setLoading(true);
    const projectstart_date = new Date(projectstart);
    const projectstart_year = projectstart_date.getFullYear();
    const projectstart_month = String(projectstart_date.getMonth() + 1).padStart(2, '0');
    const projectstart_day = String(projectstart_date.getDate()).padStart(2, '0');
    const projectstart_formattedDate = `${projectstart_year}-${projectstart_month}-${projectstart_day}`;

    const data = {
      name: projectname,
      // location: projectlocation,
      project_category: projectcategory,
      identifier: projectid,
      company_id: parseInt(localStorage.getItem('company_id')),
      total_budget: parseFloat(projectcost),
      contract_amount: parseFloat(contractAmount),
      source_of_funds: sourcefund,
      // start_date: projectstart,
      start_date: projectstart_formattedDate,
      duration: nodays,
      region: regionAddr,
      province: provinceAddr,
      city: cityAddr,
      barangay: barangayAddr,
      street: streetAddr,
      estimate_status: 1,
      ocm: ocm,
      cp: cp,
      has_vat: hasVat,
      project_type: projectType,
    };

    SaveProject(data)
      .then((response) => {
        if (response.data.status == 200) {
          setLoading(false);
          data['id'] = response.data.response.id; // insert id
          props.Overview(data, 'Overview');
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.response,
            text: response.data.description,
            confirmButtonColor: '#23303D',
            // footer: '<a href="">Why do I have this issue?</a>',
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setLoading(false);
      });
  };

  const cancel = () => {
    window.location.reload(true);
  };

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center">
        <div className="row w-100">
          <div className="col-12 col-md-7 mx-auto">
            {loading && <Loading />}
            <div className="text-center">
              <h3 className="fw-bold">Let's Connect!</h3>
            </div>
            <div className="card p-4 shadow" style={{ borderRadius: '25px' }}>
              <div className="text-left mb-2">
                <h6>Project Information</h6>
                <span
                  style={{
                    color: '#B42A19',
                    fontSize: '12px',
                    fontStyle: 'italic',
                  }}
                >
                  Note:all fields with (*) is required to be filled out.
                </span>
              </div>
              <div className="mb-2">
                <label htmlFor="projectName" className="form-label">
                  Project Name
                </label>
                {projectname == '' && (
                  <span
                    className="col-md-1"
                    style={{
                      color: '#B42A19',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    *
                  </span>
                )}
                <CInput type="text" placeholder="Project Name" size="md" onChange={(e) => setProjectName(e.target.value)} value={projectname} className="input-design" />
              </div>

              <div className="mb-2">
                <label htmlFor="region" className="form-label">
                    Region
                </label>
                <CSelect onChange={province} onSelect={region} placeholder="Region">
                  <option>Select Region</option>
                  {regionData &&
                    regionData.length > 0 &&
                    regionData.map((item) => (
                      <option key={item.region_code} value={item.region_code}>
                        {item.region_name}
                      </option>
                    ))}
                </CSelect>
              </div>

              <div className="mb-2">
                  <label htmlFor="province" className="form-label">
                    Province
                  </label>
                <CSelect onChange={city} disabled={regionAddr == ''} placeholder="Province">
                  <option value="">Select Province</option>
                  {provinceData &&
                    provinceData.length > 0 &&
                    provinceData.map((item) => (
                      <option key={item.province_code} value={item.province_code}>
                        {item.province_name}
                      </option>
                    ))}
                </CSelect>
              </div>

              <div className="mb-2">
                <label htmlFor="city" className="form-label">
                    City/Municipality
                  </label>
                <CSelect id="city" className="form-select" onChange={barangay} disabled={provinceAddr === ''}>
                  <option>Select City</option>
                  {cityData &&
                    cityData.map((item) => (
                      <option key={item.city_code} value={item.city_code}>
                        {item.city_name}
                      </option>
                    ))}
                </CSelect>
              </div>

              <div className="mb-2">
              <label htmlFor="barangay" className="form-label">
                    Barangay
                  </label>
                <CSelect id="barangay" className="form-select" onChange={brgy} disabled={cityAddr === ''}>
                  <option>Select Barangay</option>
                  {barangayData &&
                    barangayData.map((item) => (
                      <option key={item.brgy_code} value={item.brgy_code}>
                        {item.brgy_name}
                      </option>
                    ))}
                </CSelect>
              </div>

              <div className="mb-2">
              <label htmlFor="street" className="form-label">
                    Street Name
                  </label>
                <CInput type="text" placeholder="Street Name" size="md" onChange={(e) => setStreetAddr(e.target.value)} value={streetAddr} className="input-design" />
              </div>

              <div className="mb-2">
                <label htmlFor="barangay" className="form-label">
                  Implementing Client:
                </label>
                {sourcefund == '' && (
                  <span
                    className="col-md-1"
                    style={{
                      color: '#B42A19',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    *
                  </span>
                )}
                <CSelect value={sourcefund} onChange={(e) => setSourceFund(e.target.value)}>
                  <option value="">Implementing Client</option>
                  <option value="DPWH"> DPWH</option>
                  <option value="Provincial Government">Provincial Government</option>
                  <option value="Local Government Unit">Local Government Unit</option>
                  <option value="Private">Private</option>
                  <option value="Joint Venture">Joint Venture</option>
                </CSelect>
              </div>

              <div className="mb-2">
                <label htmlFor="barangay" className="form-label">
                  Project Category:
                </label>
                {projectcategory == '' && (
                  <span
                    className="col-md-1"
                    style={{
                      color: '#B42A19',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    *
                  </span>
                )}
                <CSelect placeholder="Project Category" size="md" onChange={(e) => setProjectCategory(e.target.value)} value={projectcategory} className="input-design">
                  <option disabled value="">
                    Project Category
                  </option>

                  {categoryOptiom}
                </CSelect>
              </div>
              <div className="mb-2">
                <label htmlFor="projectType" className="form-label">
                  Project Type
                </label>
                {projectType == '' && (
                  <span
                    className="col-md-1"
                    style={{
                      color: '#B42A19',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    *
                  </span>
                )}
                <CSelect id="projectType" className="form-select" value={projectType} onChange={(e) => setProjectType(e.target.value)}>
                  <option disabled value="">
                    Select Project Type
                  </option>
                  {option}
                </CSelect>
              </div>

              <div className="mb-2">
                <label htmlFor="street" className="form-label">
                  Project ID
                </label>
                {projectid == '' && (
                  <span
                    className="col-md-1"
                    style={{
                      color: '#B42A19',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    *
                  </span>
                )}
                <CInput type="text" placeholder="Project ID" size="md" onChange={(e) => setProjectId(e.target.value)} value={projectid} className="input-design" />
              </div>

              <div className="mb-2">
                <label htmlFor="street" className="form-label">
                  Project Start
                </label>
                {projectstart == '' && (
                  <span
                    className="col-md-1"
                    style={{
                      color: '#B42A19',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    *
                  </span>
                )}
                <DatePicker
                  className="input-design form-control"
                  placeholderText="Start Date"
                  format="MM/dd/yyyy"
                  value={projectstart}
                  onChange={(value) => {
                    const date = new Date(value);
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const year = date.getFullYear();
                    const formattedValue = `${month}/${day}/${year}`;

                    setProjectStart(formattedValue);
                  }}
                />
              </div>

              <div className="mb-2">
              <label htmlFor="street" className="form-label">
                    No of Days
                  </label>
                <CInput type="number" placeholder="No of Days" size="md" onChange={(e) => setNoDays(e.target.value)} className="input-design" value={nodays} />
              </div>
              <div className="mb-2">
                  <label htmlFor="street" className="form-label">
                    Project Cost
                    <CTooltip content="The Approved Budget for the Contract." placement="right-end" color="white">
                        <FaRegQuestionCircle
                            style={{
                              width: '15px',
                              height: '15px',
                              marginLeft: '5px',
                              marginBottom: '3px',
                              color: '#757070',
                            }}
                          />
                      </CTooltip>
                  </label>
                <CInput type="number" placeholder="Project Cost" size="md" onChange={(e) => setProjectCost(e.target.value)} value={projectcost} className="input-design" />
              </div>
              <div className="mb-2">
                <label htmlFor="street" className="form-label">
                    Contract Amount
                    <CTooltip content="Bid Amount" placement="right-end" color="white">
                        <FaRegQuestionCircle
                            style={{
                              width: '15px',
                              height: '15px',
                              marginLeft: '5px',
                              marginBottom: '3px',
                              color: '#757070',
                            }}
                          />
                      </CTooltip>
                  </label>
                <CInput type="number" placeholder="Contract Amount" size="md" onChange={(e) => setContractAmount(e.target.value)} value={contractAmount} className="input-design" />
              </div>
              <div className="mb-2">
                <label htmlFor="street" className="form-label">
                    OCM %
                  </label>
                <CInput type="number" placeholder="OCM %" size="md" value={ocm} onChange={(e) => setOcm(e.target.value)} className="input-design" />
              </div>
              <div className="mb-2">
                <label htmlFor="street" className="form-label">
                    Contractor's Profit %
                  </label>
                <CInput type="number" placeholder="Contractor's Profit %" size="md" onChange={(e) => setCp(e.target.value)} className="input-design" value={cp} />
              </div>
              <div className="mb-2">
              <label htmlFor="street" className="form-label">
                    VAT Type
                  </label>
                <CSelect value={hasVat} onChange={(e) => setHasVat(e.target.value)}>
                  <option disabled value="">
                    VAT Type
                  </option>
                  <option value="1">Vatable</option>
                  <option value="0">Without VAT</option>
                </CSelect>
              </div>
              <CCol sm="12" md="12" className="float-right mt-3">
                <CRow>
                  <CCol md="6" sm="12">
                    <CButton color="secondary_base" variant="outline" size="md" block onClick={cancel}>
                      Cancel
                    </CButton>
                  </CCol>
                  <CCol md="6" sm="12">
                    <CButton
                      color="secondary_base"
                      size="md"
                      block
                      onClick={Save}
                      disabled={
                        !projectname ||
                        !projectid ||
                        !regionData ||
                        !provinceData ||
                        !cityData ||
                        !barangayData ||
                        !projectstart ||
                        !nodays ||
                        !projectcategory ||
                        // !hasVat ||
                        !projectType
                      }
                    >
                      Connect
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProject;
